.LogItemDisplay {
  margin: 10px;
  padding: 15px;
  max-width: 100%;
  width: auto;
  border: 1px solid grey;
  border-radius: 4px;
  max-height: 85vh;
  height: auto;
  overflow-y: scroll;
  text-overflow: wrap;
  overflow-wrap: break-word;
}

.LogItemCard {
  display: inline;
  margin-left: auto;
  margin-right: auto;
  width: 50vw;
}

#nothingtoshow {
  color: white;
}

/* Let's get this party started */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: rgba(55, 178, 77, 0.6);
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(55, 178, 77, 0.25);
}
