.App {
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;

  overflow: hidden;

  background-color: #1e1e1e;
}

.LogoHeader {
  padding-top: 1vh;
  padding-left: 0.5vw;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.FilterList {
  position: relative;
  display: flex;
  height: 100vh;
  flex: 3;
  flex-direction: column;
  padding: 4px;
  background-color: #e1e1e1;
}

.MainContent {
  height: auto;
  overflow: hidden;
}

.LogDisplay {
  flex: 8;
}

.LogDisplayTermSearch {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#TermSearchInput {
  flex: 1;
  width: 40vw;
}

#HighlightTermBtn {
  margin-left: 0.5vw;
}

.NotificationBody {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

#HighlightInputClearBtn {
  margin: 5px;
}

#LogItemDisplayPaginator {
  margin-left: 2em;
}

.ConversationLog {
  background-color: #3e3e3e;
  flex: 3;
  display: flex;
}

.Notice {
  padding: 2px;
  margin-top: auto;
  margin-bottom: 1vh;
  display: flex;
  flex-direction: column;
}

.LegaleseRow {
  flex-direction: row;
  align-items: space-between;
}
