.LogItemText {
  color: white;
}

.LogItemText:hover {
  background-color: rgb(61, 61, 61);
  cursor: pointer;
}

.LogItemDate {
  color: rgb(129, 129, 129);
}

.LogItemUsername {
  color: rgba(52, 151, 43);
}

.LogItemUsername:hover {
  cursor: pointer;
  color: rgba(52, 151, 43, 0.5);
}

.LogItemMessage {
  color: rgb(255, 255, 255);
}

.LogItemHighlightedText {
  background-color: rgba(114, 114, 0, 0.8);
}

span.LogItemTextSlashCommand {
  color: yellow;
}

span.LogItemTextCommand {
  color: orange;
}

span.LogItemEmote {
  color: grey;
}

span.LogItemTextMentionUsername {
  font-weight: bold;
  color: rgb(106, 81, 151);
}

span.LogItemTextMentionUsername:hover {
  text-decoration: underline;
  color: rgba(155, 155, 155, 0.5);
  cursor: pointer;
}

.LogItemMessage a {
  color: rgb(151, 161, 253);
}

.LogItemMessage a:visited {
  color: rgba(151, 161, 253, 0.055);
}
.LogItemMessage a:hover {
  color: rgb(95, 102, 160);
}
