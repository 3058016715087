.ConvoViewerMain {
  display: flex;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
}

.ConvoTitleBar {
  background-color: #1e1e1e;
  color: #e1e1e1;
}

.ConvoBodyMain {
  height: 100%;
  display: flex;
  flex-direction: column;
}

#ConversationTitle {
  margin: 15pt;
}

.ConversationBody {
  margin: 10px;
  flex: 1;
  background-color: #e1e1e1;
}
