.LogoHeader {
  position: relative;
  height: 50px;
}

.LogoImage {
  position: relative;
  margin-right: 10px;
  transition: filter 0.5s ease-in-out 10ms;
  transition: transform 0.05s ease-in-out 1ms;
}

#raccoonhead {
  position: relative;
  height: 50px;
}

#raccoonearleft {
  position: absolute;
  height: 50px;
  top: 0;
  left: 0;
}

#raccoonearright {
  position: absolute;
  height: 50px;
  top: 0;
  left: 0;
}

#raccoonhand {
  position: absolute;
  height: 50px;
  top: 0;
  left: 0;
}

#documentIcon {
  position: absolute;
  height: 20px;
  top: 0;
  left: 0;
}
