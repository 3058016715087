.RawTimeEntry {
  display: flex;
  flex-direction: row;
  height: auto;
}

.RTETextInput {
  align-self: center;
  width: 100%;
}

.RTEAdjustButton {
  align-self: last baseline;
  margin-left: 0.5vw;
}

.AdjustBtnRow {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-left: 1vw;
  margin-right: 1vw;
  margin-top: 1vh;
  margin-bottom: 1vh;
  align-items: baseline;
}

.AdjustRowText {
  margin-left: 1vw;
  margin-right: 1vw;
  align-self: baseline;
  flex: 1;
  text-align: center;
}

.AdjustRowButton {
  margin-left: 0.25vw;
}
