.ConversationDisplay {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: 10px;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  text-overflow: wrap;
  overflow-wrap: break-word;
  color: #e9e9e9;
}

.ConversationDisplayTitleRow {
  flex-direction: row;
  display: flex;
}

#ConversationBuilderTitle {
  color: #e9e9e9;
  flex: 1;
}

.ConversationList {
  flex-grow: 1;
  overflow-y: scroll;
  height: auto;
}

.ConversationLogItem {
  margin: 1vh 0 5px 0;
  margin-right: 0.5vw;
  flex-direction: column;
  background-color: rgb(88, 88, 88);
  color: white;
  word-wrap: break-word;
  word-break: break-all;
}

.CardRow {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
  margin-top: -0.5vh;
  word-wrap: break-word;
}

#ConvoOptionButtons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  position: relative;
}

#SaveConvo {
  flex: 1;
  margin-left: 0.5vw;
  margin-right: 0.25vw;
}

#ExportConvo {
  flex: 1;
  margin-left: 0.25vw;
  margin-right: 0.5vw;
  width: 50%;
}
